import React from "react";

import { isBrowser } from "../../../helpers";

export default function FullscreenImage({ image, closeModal, isActive }) {
  if (!isBrowser) return null;
  if (!image) return null;
  if (!isActive) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        padding: "12px",
        width: "100%",
        height: "100%",
        zIndex: 20,
        backgroundImage: `url(${image})`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
      }}
    >
      <button
        onClick={() => closeModal()}
        style={{
          position: "fixed",
          top: 8,
          right: 8,
          color: "#ffffff",
          border: "none",
          width: 32,
          height: 32,
          boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
          textAlign: "center",
          borderRadius: 25,
          marginBottom: 8,
          textDecoration: "none",
          backgroundColor: "#c8102e",
        }}
      >
        X
      </button>
    </div>
  );
}
