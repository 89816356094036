import React from "react";

import { isBrowser } from "../../../helpers";

export default function ZoomedImage({ image, posX, offsetX, posY, offsetY }) {
  if (!isBrowser) return null;
  if (!image) return null;

  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10,
        top: 0,
        left: "100%",
        padding: "12px",
        paddingBottom: "100%",
        width: windowWidth - posX - 40,
        backgroundImage: `url(${image})`,
        backgroundPositionX: `${offsetX}%`,
        backgroundPositionY: `${offsetY}%`,
        backgroundSize: "200%",
      }}
    ></div>
  );
}
