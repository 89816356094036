import React from "react";
import { withStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import Cookies from "js-cookie";
import { connect } from "react-redux";

import styles from "./styles";
import Link from "../../Link";

import ProductGallery from "../ProductGallery";
import ProductShare from "../ProductShare";
import ProductTabs from "../ProductTabs";
import { addToWishlist, removeFromWishlist } from "../../../app/actions";
import { isBrowser, priceFormatter } from "../../../helpers";
import { isLoggedIn } from "../../../services/auth";

import siteReady from "../../../images/site-ready.png";
import logo from "../../../images/tower-2019-logo-RBG.png";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function Logo(props) {
  if (!props.brands) return null;
  if (!props.brands.nodes) return null;
  if (!props.brands.nodes[0]) return null;
  if (!props.brands.nodes[0].BrandExtras.logo) return null;

  return (
    <img
      className="brand-logo"
      src={props.brands.nodes[0].BrandExtras.logo}
      alt=""
    />
  )
}

class ProductOverview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      group: null,
      authenticated: undefined,
    }

    this.wishlistHandler = this.wishlistHandler.bind(this)

    isBrowser &&
      this.state.authenticated === undefined &&
      this.handleAuthentication()
  }

  async handleAuthentication() {
    const response = await isLoggedIn()

    if (response.data.errors) {
      this.setState({
        authenticated: false,
      })
    } else {
      this.setState({
        authenticated: response.data.data.user,
      })
      const group = response.data.data.user.userGroup.group.slug

      if (group) {
        this.setState({ group })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wishlist !== this.props.wishlist) {
      Cookies.set("wishlist", this.props.wishlist)
    }
  }

  handleOpen = () => this.setState({ open: !this.state.open })

  wishlistHandler() {
    const { wishlist, addToWishlist, removeFromWishlist, id } = this.props

    if (wishlist.includes(id)) {
      removeFromWishlist(id)
    } else {
      addToWishlist(id)
    }
  }

  render() {
    const {
      id,
      wishlist,
      brands,
      title,
      code,
      rrpPrice,
      tradePrice,
      memberPrice,
      rrpPricePublic,
      gallery,
      path,
      classes,
    } = this.props
    const props = this.props
    const { group } = this.state

    const inWishlist = wishlist.includes(id)

    return (
      <div
        className={`product-overview  ${classes.productOverview}  ${classes.productOverviewTheme}`}
      >
        <button
          className={inWishlist ? "wishlist added" : "wishlist"}
          onClick={this.wishlistHandler}
        >
          Add to Wish List
        </button>
        <div className="title-wrap mobile">
          <Logo brands={brands} />
          <h1>{ReactHtmlParser(title)}</h1>
          <p className="code">{code}</p>
        </div>

        {props.gallery !== null && (
          <ProductGallery images={gallery}>
            <ProductShare path={path} title={title} />
          </ProductGallery>
        )}
        <div className="overview">
          {(group || rrpPricePublic) && (
            <div className={"prices mobile " + group}>
              {rrpPricePublic && !group && (
                <div className={`price rrp`}>
                  <span>
                    RRP <strong>{priceFormatter(rrpPricePublic)}</strong>
                  </span>
                </div>
              )}
              {memberPrice && group === "member" && (
                <div className={`price member`}>
                  <span>
                    Member <strong>{priceFormatter(memberPrice)}</strong>
                  </span>
                </div>
              )}
              {tradePrice && (group === "trade" || group === "member") && (
                <div className={`price trade`}>
                  <span>
                    Trade <strong>{priceFormatter(tradePrice)}</strong>
                  </span>
                </div>
              )}
              {rrpPrice && (group === "trade" || group === "member") && (
                <div className={`price rrp`}>
                  <span>
                    RRP <strong>{priceFormatter(rrpPrice)}</strong>
                  </span>
                </div>
              )}
              <div className={"con-wrap " + group}>
                <img src={logo} alt="Tower" />
                <p>Workshop Pro Finance</p>
                <p>
                  <Link to="/finance/">Learn more</Link>
                </p>
              </div>
            </div>
          )}
          <div className="title-wrap desktop">
            <Logo brands={props.brands} />
            <h1>{ReactHtmlParser(props.title)}</h1>
            <p className="code">{props.code}</p>
            {(group || rrpPricePublic) && (
              <div className={"prices desktop " + group}>
                {rrpPricePublic && !group && (
                  <div className={`price rrp`}>
                    <span>
                      RRP <strong>{priceFormatter(rrpPricePublic)}</strong>
                    </span>
                  </div>
                )}
                {memberPrice && group === "member" && (
                  <div className={`price member`}>
                    <span>
                      Member <strong>{priceFormatter(memberPrice)}</strong>
                    </span>
                  </div>
                )}
                {tradePrice && (group === "trade" || group === "member") && (
                  <div className={`price trade`}>
                    <span>
                      Trade <strong>{priceFormatter(tradePrice)}</strong>
                    </span>
                  </div>
                )}
                {rrpPrice && (group === "trade" || group === "member") && (
                  <div className={`price rrp`}>
                    <span>
                      RRP <strong>{priceFormatter(rrpPrice)}</strong>
                    </span>
                  </div>
                )}
                <div className={"con-wrap " + group}>
                  <img src={logo} alt="Tower" />
                  <p>Workshop Pro Finance</p>
                  <p>
                    <Link to="/finance/">Learn more</Link>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="actions">
            {props.siteReady !== true && (
              <Link
                to="/enquiry"
                state={{
                  product: {
                    id: props.id,
                    title: props.title,
                    code: props.code,
                  },
                }}
                className="enquire"
              >
                Enquire Now
              </Link>
            )}
            {props.siteReady === true && (
              <button
                className="enquire"
                onClick={this.handleOpen}
                onKeyDown={this.handleOpen}
              >
                Enquire Now
              </button>
            )}
            <Link to="/distributors" className="stockists">
              Search for distributors
            </Link>
          </div>
          <div className="overview-content">
            <h2>Overview</h2>
            <h3>{ReactHtmlParser(props.title)}</h3>
            {ReactHtmlParser(props.desc)}
            {props.features != null && (
              <ul className="features">
                {props.features.map((feature) => {
                  return <li>{ReactHtmlParser(feature.feature)}</li>;
                })}
              </ul>
            )}
          </div>
        </div>
        {props.techSpec !== null && props.download !== null && (
          <div className="content-tabs">
            <ProductTabs
              techSpecs={props.techSpec}
              downloads={props.downloads}
            />
          </div>
        )}
        <div className="site-ready" open={this.state.open}>
          <button onClick={this.handleOpen} onKeyDown={this.handleOpen}>
            Close
          </button>
          <div className="content">
            <h3>Is your site ready? </h3>
            <p>
              In order for our expert installation engineer to install your
              newly-purchased lifting equipment, it’s important to confirm that
              all the pre-installation and site preparation requirements are
              complete.{" "}
            </p>
            <div className="links">
              <Link
                to="/enquiry"
                state={{
                  product: {
                    id: props.id,
                    title: props.title,
                    code: props.code,
                  },
                }}
                className="button"
              >
                We're site ready
              </Link>
              <Link to="/site-ready/">Find out more</Link>
            </div>
          </div>
          <div className="image-wrap">
            <img
              src={siteReady}
              alt="Prepare your site for our expert installation engineer to install your newly-purchased lifting equipment... "
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connector(ProductOverview));
