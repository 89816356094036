import tabActive from "../../../images/active-tab.png";

export default (theme) => ({
  root: {
    position: "relative",
    marginBottom: 36,
    "& .MuiAppBar-colorDefault": {
      width: "calc(100% + 34px)",
      margin: "0 -17px",
      backgroundColor: "transparent",
      boxShadow: "none",
      paddingLeft: 17,
      position: "relative",
      marginBottom: 50,
      zIndex: 1,
      "&::after": {
        left: 0,
        bottom: -2,
        height: 2,
        backgroundColor: theme.palette.secondary.main,
        display: "block",
        right: 0,
        content: '""',
        zIndex: -1,
      },
    },
    "& .MuiTabs-root": {
      overflow: "visible",
      "& .MuiTabs-scrollable": {
        overflowX: "visible",
        overflowY: "visoble",
      },
    },
    "& .MuiTab-root": {
      padding: "16px 30px",
      fontFamily: "Roboto Condensed",
      fontSize: 18,
      lineHeight: 1,
      letterSpacing: 0,
      textTransform: "none",
      position: "relative",
      overflow: "visible",
      zIndex: 1,
      transition: "color .3s ease-in-out",
      "&:hover, &:focus": {
        color: theme.palette.secondary.main,
      },
      "& span": {
        zIndex: 1,
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
      "&::before": {
        content: '""',
        display: "block",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        backgroundColor: "white",
        position: "absolute",
      },
      "&::after": {
        content: '""',
        width: 86,
        height: 26,
        backgroundImage: "url(" + tabActive + ")",
        display: "block",
        position: "absolute",
        top: "calc(100% - 28px)",
        left: "calc(50% - 43px)",
        zIndex: -1,
      },
      "&.Mui-selected::after": {
        top: "100%",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& table.tech-specs": {
      borderBottom: "none",
      [theme.breakpoints.up("md")]: {
        width: "85%",
        margin: "0 auto",
      },
      "& tr": {
        "& td": {
          padding: 12,
          fontSize: 18,
          border: "none",
          position: "relative",
          [theme.breakpoints.up("md")]: {
            padding: "20px 32px",
          },
          "&::before": {
            content: '""',
            display: "block",
            top: 0,
            bottom: 0,
            right: -2,
            left: -2,
            backgroundColor: "#EDEDED",
            position: "absolute",
            zIndex: -1,
          },
        },
      },
    },
    "& .downloads": {
      [theme.breakpoints.up("md")]: {
        width: "85%",
        margin: "0 auto",
      },
      "& a": {
        marginBottom: 2,
        padding: 12,
        display: "flex",
        backgroundColor: "#EDEDED",
        color: theme.common.black,
        fontSize: 18,
        lineHeight: 1.2,
        alignItems: "center",
        textDecoration: "none",
        transition: "color .3s ease-in-out",
        "&:hover, &:focus": {
          color: theme.palette.secondary.main,
        },
        [theme.breakpoints.up("md")]: {
          padding: "20px 32px",
        },
        "& svg": {
          marginRight: 16,
          color: theme.palette.secondary.main,
          width: 24,
          [theme.breakpoints.up("md")]: {
            width: 32,
            height: 32,
          },
        },
        "& span": {
          "& i": {
            display: "block",
            fontSize: 10,
            color: theme.palette.secondary.main,
          },
        },
      },
    },
  },
});
