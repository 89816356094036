import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"

import ProductOverview from "../components/Product/ProductOverview"
import ProductBanner from "../components/Product/ProductBanner"
import ProductRelatedProducts from "../components/Product/ProductRelatedProducts"
import ProductSignUp from "../components/Product/ProductSignUp"
import ProductRelatedCourses from "../components/Product/ProductRelatedCourses"

class Product extends Component {
  render() {
    let catStack = []

    if (this.props.data.wpProduct.productCategories !== null) {
      if (this.props.data.wpProduct.productCategories.nodes.length === 1) {
        catStack.push(this.props.data.wpProduct.productCategories.nodes[0])
      } else {
        this.props.data.wpProduct.productCategories.nodes.forEach(category => {
          if (category.ancestors !== null) {
            if (category.ancestors.nodes.length >= catStack.length) {
              catStack = [
                {
                  id: category.id,
                  name: category.name,
                  slug: category.slug,
                  uri: category.uri,
                },
              ]
              category.ancestors.nodes.forEach(ancestor => {
                catStack.push(ancestor)
              })
            }
          }
        })
      }
    }

    if (catStack.length > 1) {
      catStack.reverse()
    }

    let relatedArray = []

    const relatedProducts = this.props.data.wpProduct.product.relatedProducts
    relatedProducts &&
      relatedProducts.forEach(product => {
        relatedArray.push(product.product[0])
      })

    return (
      <Layout
        meta={{
          ...this.props.data.wpProduct.Meta,
          canonical: this.props.data.wpProduct.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProduct.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="product"
          current={{
            title: this.props.data.wpProduct.title,
            slug: this.props.data.wpProduct.slug,
            uri: this.props.data.wpProduct.uri,
          }}
          ancestors={catStack}
        />
        <ProductOverview
          title={this.props.data.wpProduct.title}
          brands={this.props.data.wpProduct.brands}
          code={this.props.data.wpProduct.product.code}
          id={this.props.data.wpProduct.id}
          gallery={this.props.data.wpProduct.product.productGallery}
          desc={this.props.data.wpProduct.product.description}
          memberPrice={this.props.data.wpProduct.product.memberPrice}
          rrpPrice={this.props.data.wpProduct.product.rrpPrice}
          tradePrice={this.props.data.wpProduct.product.tradePrice}
          rrpPricePublic={this.props.data.wpProduct.product.rrpPricePublic}
          techSpec={this.props.data.wpProduct.product.techSpec}
          downloads={this.props.data.wpProduct.product.downloads}
          features={this.props.data.wpProduct.product.features}
          path={this.props.pageContext.pagePath}
          siteReady={this.props.data.wpProduct.product.siteReady}
        />
        <ProductBanner />
        {this.props.data.wpProduct.product.relatedProducts && (
          <ProductRelatedProducts
            products={this.props.data.wpProduct.product.relatedProducts}
            title="Related Products"
            default={
              this.props.data.wp.themeOptions.ThemeOptions.defaultImages
                .productImage
            }
          />
        )}
        {this.props.data.wpProduct.product.similarProducts && (
          <ProductRelatedProducts
            products={this.props.data.wpProduct.product.similarProducts}
            title="Similar Products"
            default={
              this.props.data.wp.themeOptions.ThemeOptions.defaultImages
                .productImage
            }
          />
        )}
        {this.props.data.wpProduct.product.relatedTraining && (
          <ProductRelatedCourses
            courses={this.props.data.wpProduct.product.relatedTraining}
            default={
              this.props.data.wp.themeOptions.ThemeOptions.defaultImages
                .courseImage
            }
          />
        )}
        <ProductSignUp />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpProduct(id: { eq: $id }) {
      id
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      product {
        productGallery {
          image
        }
        code
        description
        memberPrice
        tradePrice
        rrpPrice
        rrpPricePublic
        siteReady
        techSpec {
          title
          value
        }
        features {
          feature
        }
        downloads {
          title
          file
        }
        relatedProducts {
          ... on WpProduct {
            id
            title
            uri
            product {
              code
              productGallery {
                image
              }
            }
          }
        }
        similarProducts {
          ... on WpProduct {
            id
            title
            uri
            product {
              code
              productGallery {
                image
              }
            }
          }
        }
        relatedTraining {
          ... on WpCourse {
            id
            title
            uri
            courses {
              image
            }
          }
        }
      }
      productCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
      brands {
        nodes {
          name
          BrandExtras {
            logo
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          defaultImages {
            productImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            courseImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Product
