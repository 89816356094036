import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Link from '../../Link';

import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';

export default function ProductShare(props) {

    const data = useStaticQuery(graphql`
    query urlGrabProdShare {
        wp {
          locationData {
            LocationData {
              url
            }
          }
        }
      }
  `);
  
  // console.log(props);

    return (
        <div className="product-share">
            <Link className="email" to={"mailto:?subject=" + props.title + "&amp;body=" + data.wp.locationData.LocationData.url + props.path}><EmailIcon/> Share</Link>
            <Link className="whatsapp" to={"whatsapp://send?text=" + data.wp.locationData.LocationData.url + props.path}><WhatsAppIcon/> Share</Link>
            <Link className="facebook" to={"https://www.facebook.com/sharer/sharer.php?u=" + data.wp.locationData.LocationData.url + props.path}><FacebookIcon/> Share</Link>
            <Link className="twitter" to={"https://twitter.com/intent/tweet?text=" + props.title + "&url=" + data.wp.locationData.LocationData.url + props.path}><TwitterIcon/> Share</Link>
        </div>
    )
}