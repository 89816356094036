import React from "react";
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import { useStaticQuery, graphql } from "gatsby";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";
import Img from "gatsby-image";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import { CoreParagraphBlock } from "../../blocks/CoreParagraphBlock";
import { CoreButtonBlock } from "../../blocks/CoreButtonBlock";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductBanner(props) {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query productPageBanner {
      wp {
        themeOptions {
          ThemeOptions {
            productPageBanner {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heading
              text
              buttonText
              buttonLink
            }
          }
        }
      }
    }
  `);

  // console.log(props);

  return (
    <div className={`product-banner ${classes.productBanner}`}>
      <div className="tab-sep flip-tab">
        <div className="top white" />
      </div>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        className="product-banner"
      >
        <Grid item xs={12} className="image-wrap">
          <Img
            fluid={
              data.wp.themeOptions.ThemeOptions.productPageBanner.image
                .localFile.childImageSharp.fluid
            }
          />
        </Grid>
        <Grid item xs={12} className="con-wrap">
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: ReactHtmlParser(
                data.wp.themeOptions.ThemeOptions.productPageBanner.heading
              ),
              level: 2,
              backgroundColor: "",
              textColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <CoreParagraphBlock
            attributes={{
              align: "",
              content: ReactHtmlParser(
                data.wp.themeOptions.ThemeOptions.productPageBanner.text
              ),
              textColor: "foreground_primary",
              backgroundColor: "",
              __typename: "WpCoreParagraphBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <CoreButtonBlock
            attributes={{
              align: "",
              backgroundColor: "secondary",
              borderRadius: 0,
              className: "view-all",
              gradient: "",
              linkTarget: "",
              placeholder: "",
              rel: "",
              text: ReactHtmlParser(
                data.wp.themeOptions.ThemeOptions.productPageBanner.buttonText
              ),
              textColor: "foreground_secondary",
              title: "",
              url:
                data.wp.themeOptions.ThemeOptions.productPageBanner.buttonLink,
              __typename: "WpCoreButtonBlockAttributes",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
