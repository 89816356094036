import React from "react";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import Link from "../../Link";
import Img from "gatsby-image";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import { CoreButtonBlock } from "../../blocks/CoreButtonBlock";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductRelatedCourses(props) {
  const classes = useStyles();

  // console.log(props);

  return (
    <div className={`related-courses ${classes.productRelatedCourses}`}>
      <div className="block-title">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Related Training",
            level: 2,
            backgroundColor: "",
            textColor: "primary",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <CoreButtonBlock
          attributes={{
            align: "",
            backgroundColor: "foreground_secondary",
            borderRadius: 0,
            className: "",
            gradient: "",
            linkTarget: "",
            placeholder: "",
            rel: "",
            text: "View All Trianing",
            textColor: "secondary",
            title: "",
            url: `/traning/`,
            __typename: "WpCoreButtonBlockAttributes",
          }}
        />
      </div>
      <Grid container spacing={3} direction="row">
        {props.courses.map((course) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Link to={course.uri} className="course-item">
                {course.courses.image !== null && (
                  <img src={course.courses.image} alt="" />
                )}
                {course.courses.image === null && props.default && (
                  <Img fluid={props.default.localFile.childImageSharp.fluid} />
                )}
                <span className="name">{course.title}</span>
                <span className="link">View Course</span>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
