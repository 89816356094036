import React from "react";

export default function Slide({
  image,
  index,
  onMouseEnterHandler,
  onMouseOutHandler,
  onHoverHandler,
  onMouseClickHandler,
}) {
  const video =
    image.image.includes("youtube") || image.image.includes("youtu.be");
  if (video) {
    const id = image.image
      .split("/")
      .pop()
      .replace("watch?v=", "");
    return (
      <div key={"slider-image" + index}>
        <iframe
          width="437"
          height="327"
          src={`https://www.youtube.com/embed/${id}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          controls="0"
          modestbranding="1"
        ></iframe>
      </div>
    );
  }
  return (
    <div //eslint-disable-line
      key={"slider-image" + index}
      onMouseEnter={(e) => onMouseEnterHandler(e, image.image)}
      onMouseOut={() => onMouseOutHandler()}
      onMouseMove={(e) => onHoverHandler(e)}
      onClick={(e) => onMouseClickHandler(e, image.image)}
    >
      <img src={image.image} alt="" />
    </div>
  );
}
