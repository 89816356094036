import React, { Component } from "react";
import Slider from "react-slick";

import Slide from "./Slide";
import Thumbnail from "./Thumbnail";
import ZoomedImage from "./ZoomedImage";
import FullscreenImage from "./FullscreenImage";

export default class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      image: null,
      posX: null,
      poxY: null,
      offsetX: null,
      offsetY: null,
      activeFullScreen: false,
    };

    this.sliderRef = React.createRef();

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseOutHandler = this.onMouseOutHandler.bind(this);
    this.onHoverHandler = this.onHoverHandler.bind(this);
    this.onMouseClickHandler = this.onMouseClickHandler.bind(this);
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  onHoverHandler(e) {
    this.setState({
      offsetX:
        ((e.clientX - this.sliderRef.current.getBoundingClientRect().left) /
          this.sliderRef.current.getBoundingClientRect().width) *
        100,
      offsetY:
        ((e.clientY - this.sliderRef.current.getBoundingClientRect().top) /
          this.sliderRef.current.getBoundingClientRect().height) *
        100,
    });
  }

  onMouseEnterHandler(e, image) {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (windowWidth <= 960) return;

    const bounds = this.sliderRef.current.getBoundingClientRect();
    const imageBounds = this.sliderRef.current
      .querySelector("img")
      .getBoundingClientRect();

    this.setState({
      image: image.replace(/ /g, "_"),
      width: imageBounds.width,
      height: imageBounds.height,
      posX: bounds.x + bounds.width,
      posY: bounds.y + bounds.height,
    });
  }

  onMouseOutHandler() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (windowWidth < 960) return;
    if (this.state.activeFullScreen) return;

    setTimeout(() => {
      this.closeModal();
    }, 250);
  }

  onMouseClickHandler(e, image) {
    this.setState({
      image,
      width: "100%",
      posX: 0,
      posY: 0,
      activeFullScreen: true,
    });
  }

  closeModal() {
    this.setState({ image: null, activeFullScreen: false });
  }

  render() {
    const { children } = this.props;
    return (
      <div
        className={`product-gallery ${
          this.state.activeFullScreen ? "is-active" : ""
        }`}
      >
        <div ref={this.sliderRef}>
          <div className="slider" style={{ cursor: "pointer" }}>
            <Slider
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
              arrows={false}
              dots={false}
              className="image-slider"
            >
              {this.props.images.map((image, index) => {
                return (
                  <Slide
                    image={image}
                    key={index}
                    onMouseEnterHandler={this.onMouseEnterHandler}
                    onHoverHandler={this.onHoverHandler}
                    onMouseOutHandler={this.onMouseOutHandler}
                    onMouseClickHandler={this.onMouseClickHandler}
                  />
                );
              })}
            </Slider>
          </div>
          {this.props.images.length > 1 && (
            <Slider
              asNavFor={this.state.nav1}
              ref={(slider) => (this.slider2 = slider)}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
              dots={false}
              infinite={false}
              className="slider-nav"
            >
              {this.props.images.map((image, index) => {
                return <Thumbnail image={image} key={index} index={index} />;
              })}
            </Slider>
          )}
          <ZoomedImage
            image={this.state.image}
            posX={this.state.posX}
            offsetX={this.state.offsetX}
            posY={this.state.posY}
            offsetY={this.state.offsetY}
            width={this.state.width}
            height={this.state.height}
          />
          <FullscreenImage
            image={this.state.image}
            isActive={this.state.activeFullScreen}
            closeModal={() => this.closeModal()}
          />
          {children}
        </div>
      </div>
    );
  }
}
