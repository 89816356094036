export default theme => ({
    productRelatedCourses: {
        position: 'relative',
        paddingTop: 50,
        paddingBottom: 36,
        marginBottom: 50,
        '& .block-title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 32,
        },
        '& .course-item': {
            backgroundColor: 'white',
            boxShadow: '0 8px 16px rgba(0,0,0,0.16)',
            padding: '44px 44px 86px',
            textDecoration: 'none',
            position: 'relative',
            display: 'block',
            height: '100%',
            marginBottom: 24,
            '& .gatsby-image-wrapper, & img': {
                marginBottom: 32,
            },
            '& span.name': {
                fontFamily: theme.headerFont,
                color: theme.palette.primary.main,
                letterSpacing: '0.016em',
                lineHeight: 1,
                fontSize: 24,
                textDecoration: 'none',
                display: 'block',
                marginBottom: 12,
                fontWeight: 'bold',
                height: 72,
                overflow: 'hidden',
            },
            '& span.link': {
                position: 'absolute',
                borderTop: '1px solid #EDEDED',
                bottom: 32,
                left: 44,
                right: 44,
                fontFamily: theme.headerFont,
                color: theme.palette.secondary.main,
                fontSize: 18,
                fontWeight: 'bold',
                letterSpacing: '0.016em',
                textAlign: 'center',
                lineHeight: 1.2,
                paddingTop: 32,
            }
        }
    }
});