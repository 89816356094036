import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import Box from "@material-ui/core/Box/Box";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Link from '../../Link';
import ReactHtmlParser from "react-html-parser";

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => styles(theme));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ProductTabs(props) {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className={`tab-content ${classes.root}`}>
        <AppBar position="static" color="default">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {props.techSpecs !== null && 
                    <Tab label={"Technical Specifications"} {...a11yProps(0)} />
                }
                {props.downloads !== null && 
                    <Tab label={"Downloads"} {...a11yProps(1)} />
                }
            </Tabs>
        </AppBar>
        {props.techSpecs !== null && 
            <TabPanel value={value} index={0}>
                <table className="tech-specs">
                    {props.techSpecs.map(detail => {
                        return <tr className="detail">
                            <td className="title">{ReactHtmlParser(detail.title)}:</td>
                            <td className="value">{ReactHtmlParser(detail.value)}</td>
                        </tr>
                    })}
                </table>
            </TabPanel>
        }
        {props.downloads !== null && 
            <TabPanel value={value} index={1}>
                <div className="downloads">
                    {props.downloads.map(file => {
                        return <Link to={file.file}>
                            <GetAppIcon />
                            <span>{ReactHtmlParser(file.title)}</span>
                        </Link>
                    })}
                </div>
            </TabPanel>
        }
    </div>
};