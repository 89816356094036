import liBullet from "../../../images/li-bullet.png";

import starWhite from "../../../images/star-regular-white.png";
import starFilledWhite from "../../../images/star-filled-white.png";
import star from "../../../images/star-regular.png";
import starFilled from "../../../images/star-filled.png";

import pin from "../../../images/map-pin.png";

import close from "../../../images/icon-close.png";

export default (theme) => ({
  productOverview: {
    paddingTop: 32,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 50,
      display: "flex",
      margin: "0 -12px",
      width: "calc(100% + 24px)",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "&::before": {
      backgroundColor: "white",
      content: '""',
      position: "absolute",
      zIndex: -1,
      top: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 0,
    },
    "& button.wishlist": {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      border: "none",
      boxShadow: "0 -7px 9px rgba(25,54,91,.5)",
      borderTopLeftRadius: 35,
      borderTopRightRadius: 35,
      display: "block",
      fontSize: 18,
      fontFamily: theme.headerFont,
      lineHeight: 1.5,
      letterSpacing: "0.016em",
      padding: 24,
      width: "100%",
      "&::after": {
        content: '""',
        display: "inline-block",
        marginLeft: 12,
        width: 22,
        height: 20,
        verticalAlign: "text-top",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + starWhite + ")",
      },
      "&.added": {
        "&::after": {
          backgroundImage: "url(" + starFilledWhite + ")",
          [theme.breakpoints.up("md")]: {
            backgroundImage: "url(" + starFilled + ")",
          },
        },
      },
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        bottom: "auto",
        top: 50,
        right: 12,
        zIndex: 1,
        width: "auto",
        left: "auto",
        padding: 0,
        backgroundColor: "transparent",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: "none",
        color: theme.palette.secondary.main,
        "&::after": {
          backgroundImage: "url(" + star + ")",
        },
      },
      [theme.breakpoints.up("lg")]: {
        right: "calc(6.25% + 12px)",
      },
    },
    "& .title-wrap": {
      "&.mobile": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      "&.desktop": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
      "& .brand-logo": {
        width: 100,
        margin: 0,
      },
      "& h1": {
        fontSize: 24,
        fontWeight: 600,
        fontFamily: theme.headerFont,
        letterSpacing: "0.016em",
        lineHeight: 1,
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        margin: "10px 0",
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
          marginTop: 20,
        },
      },
      "& .code": {
        letterSpacing: "0.152em",
        lineHeight: 1.25,
        fontSize: 12,
        fontFamily: theme.bodyFont,
        opacity: 0.3,
        margin: 0,
        textTransform: "uppercase",
        marginBottom: 30,
        [theme.breakpoints.up("md")]: {
          fontFamily: theme.headerFont,
          opacity: 1,
          fontSize: 18,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontWeight: "bold",
        },
      },
    },
    "& .prices": {
      "&.mobile": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      "&.desktop": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
      position: "relative",
      zIndex: 3,
      border: "2px solid #EDEDED",
      paddingTop: 40,
      paddingBottom: 28,
      marginLeft: 72,
      whiteSpace: "nowrap",
      marginBottom: 40,
      "& .con-wrap": {
        right: 20,
        whiteSpace: "initial",
        "&.null": {
          position: "absolute",
          top: 24,
          left: 100,
          right: 20,
          paddingLeft: 0,
          whiteSpace: "normal",
        },
        "&.trade": {
          paddingLeft: 72,
          [theme.breakpoints.up(480)]: {
            position: "absolute",
            top: 24,
            left: 200,
            paddingLeft: 0,
          },
        },
        "&.member": {
          paddingLeft: 72,
          [theme.breakpoints.up(620)]: {
            position: "absolute",
            top: 24,
            left: 280,
            paddingLeft: 0,
          },
        },
        "& img": {
          width: 50,
          marginBottom: 4,
        },
        "& p": {
          fontFamily: "Overpass",
          color: theme.palette.primary.main,
          fontSize: 18,
          letterSpacing: "0.016em",
          lineHeight: 1.2,
          margin: 0,
          marginBottom: 12,
          display: "block",
          textAlign: "left",
          "& a": {
            fontWeight: "bold",
            textDecoration: "underline",
            color: theme.palette.secondary.main,
            fontSize: 16,
          },
        },
      },
      "& .price": {
        direction: "ltr",
        left: 0,
        width: 135,
        height: 77.94,
        backgroundColor: "white",
        boxShadow: "0 7px 16px rgba(0,0,0,0.4)",
        display: "inline-block",
        position: "relative",
        animation: "none !important",
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          width: 95.46,
          height: 95.46,
          transform: "scaleY(0.5774) rotate(-45deg)",
          backgroundColor: "inherit",
          left: 19.7703,
          boxShadow: "0 7px 16px rgba(0,0,0,0.4)",
        },
        "&::before": {
          top: -47.7297,
        },
        "&::after": {
          bottom: -47.7297,
        },
        "& span": {
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          width: 135,
          height: 77.9423,
          zIndex: 2,
          background: "inherit",
          padding: "10px 10px",
          fontSize: 12,
          letterSpacing: "0.152em",
          lineHeight: 1.2,
          textTransform: "uppercase",
          fontFamily: theme.bodyFont,
          textAlign: "left",
          "& strong": {
            fontSize: 28,
            fontWeight: "normal",
            letterSpacign: "-0.016em",
            lineHeight: 0.8,
            fontFamily: theme.headerFont,
            marginTop: 10,
            display: "block",
          },
          "& i": {
            color: theme.palette.secondary.main,
            fontSize: 10,
            fontStyle: "normal",
          },
        },
        "&:nth-of-type(1)": {
          backgroundColor: theme.palette.primary.main,
          marginLeft: -69,
          "&::before, &::after, & span": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        },
        "&:nth-of-type(2)": {
          transform: "scale(0.66) translate(-33px, -19px)",
        },
        "&:nth-of-type(3)": {
          transform: "scale(0.66) translate(-102px, -19px)",
        },
        "&.member": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
    },
    "& .product-gallery": {
      position: "relative",
      zIndex: 5,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 43.75%",
        maxWidth: "43.75%",
        margin: 0,
        marginRight: "6.25%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 37.5%",
        maxWidth: "37.5%",
        marginLeft: "6.25%",
      },
      "& .slider-nav": {
        width: "calc(100% + 12px)",
        margin: "6px -6px 12px",
        "& .slick-slide": {
          padding: "13px",
          position: "relative",
          fontSize: 0,
          cursor: "pointer",
          "&::before": {
            position: "absolute",
            border: "2px solid #EDEDED",
            top: 6,
            left: 6,
            right: 6,
            bottom: 6,
            content: '""',
            display: "block",
            transition: "border-color .3s ease-in-out",
          },
          "& .gatsby-image-wrapper": {
            margin: 0,
          },
          "&.slick-current": {
            "&::before": {
              borderColor: theme.palette.secondary.main,
            },
          },
          "& .thumbnail": {
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.25)",
            },
            "& .video": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: 30,
              width: 30,
              display: "block",
              border: "1px solid white",
              borderRadius: "50%",
            },
          },
        },
      },
      "& .zoomBounds": {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "180px",
        height: "180px",
        backgroundSize: "100px 100px",
        backgroundRepeat: "no-repeat",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        border: "1px solid black",

        "& .image": {
          backgroundSize: "100% 100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      },
      "& .slider": { position: "relative", zIndex: 10 },
      "&.is-active": {
        zIndex: 50,
      },
    },
    "& .product-share": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: 36,
      "& a": {
        display: "block",
        margin: "0 0 5px",
        fontSize: 16,
        textTransform: "uppercase",
        fontFamily: theme.headerFont,
        fontWeight: "500",
        color: "white",
        textAlign: "center",
        borderRadius: 25,
        padding: "12px 20px",
        textDecoration: "none",
        transition: "opacity .3s ease-in-out",
        marginBottom: 10,
        width: "calc(25% - 10px)",
        [theme.breakpoints.up("md")]: {
          width: "fit-content",
        },
        "&:hover, &:focus": {
          opacity: 0.8,
        },
        "& svg": {
          display: "inline-block",
          verticalAlign: "bottom",
          marginRight: 5,
          width: 16,
        },
        "&.email": { backgroundColor: theme.palette.primary.main },
        "&.facebook": { backgroundColor: "#385C8E" },
        "&.whatsapp": { backgroundColor: "#30BA45" },
        "&.twitter": { backgroundColor: "#03A9F4" },
      },
    },
    "& .overview": {
      paddingBottom: 50,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 12px",
        margin: 0,
        paddingBottom: 80,
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 43.75%",
        maxWidth: "43.75%",
      },
      "& .actions": {
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        marginBottom: 32,
        "& button, & .enquire, & a": {
          fontSize: 18,
          fontFamily: theme.headerFont,
          textDecoration: "none",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          textAlign: "center",
          padding: "10px 30px",
          border: "none",
          marginBottom: 8,
          boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
          borderRadius: 25,
          "&.stockists": {
            backgroundColor: theme.palette.secondary.contrastText,
            color: theme.palette.secondary.main,
            "&::before": {
              content: '""',
              display: "inline-block",
              marginRight: 12,
              width: 11,
              height: 16,
              backgroundPosition: "bottom center",
              backgroundRepeat: "no-repeat",
              backgroundImage: "url(" + pin + ")",
            },
          },
        },
      },
      "& .overview-content": {
        "& h2": {
          textTransform: "none",
          color: theme.palette.primary.main,
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.008em",
          fontSize: 24,
          lineHeight: 2,
          borderBottom: "2px solid #EDEDED",
          margin: 0,
          marginBottom: 24,
          fontWeight: "normal",
        },
        "& h3": {
          textTransform: "none",
          color: theme.palette.primary.main,
          fontSize: 18,
          lineHeight: 2,
          letterSpacing: 0,
          fontFamily: "Roboto Condensed",
          margin: 0,
          fontWeight: "normal",
        },
        "& p": {
          fontSize: 16,
          lineHeight: 1.5,
          margin: "16px 0",
        },
        "& ul": {
          padding: 0,
          "& li": {
            padding: 0,
            paddingLeft: 50,
            backgroundImage: "url(" + liBullet + ")",
            backgroundPosition: "top 3px left 20px",
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: 1.5,
            display: "block",
            marginBottom: 12,
            backgroundRepeat: "no-repeat",
          },
        },
      },
    },
    "& .content-tabs": {
      marginBottom: 50,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 87.5%",
        maxWidth: "87.5%",
        marginLeft: "6.25%",
        marginBottom: 120,
      },
    },
    "& .site-ready": {
      position: "fixed",
      top: 90,
      left: "50%",
      backgroundColor: theme.palette.primary.main,
      width: "95%",
      maxWidth: 920,
      transform: "translate(-50%, 0)",
      boxShadow: "0 0 0 1000px rgba(0,0,0,0.46)",
      zIndex: 999,
      padding: 32,
      display: "none",
      "&[open]": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
      },
      "&::before": {
        backgroundColor: theme.palette.primary.main,
        content: '""',
        top: -75,
        height: 76,
        left: 0,
        right: 115,
        display: "block",
        position: "absolute",
        boxShadow: "1px 1px 0 0 " + theme.palette.primary.main,
      },
      "&::after": {
        position: "absolute",
        width: 0,
        height: 0,
        top: -75,
        right: 55,
        content: '""',
        borderStyle: "solid",
        borderWidth: "76px 0 0 60px",
        borderColor:
          "transparent transparent transparent " + theme.palette.primary.main,
      },
      [theme.breakpoints.up("md")]: {
        padding: "32px 64px 64px",
        top: "50%",
        transform: "translate(-50%,-50%)",
      },
      "& button": {
        width: 75,
        height: 75,
        textAlign: "center",
        fontFamily: theme.headerFont,
        fontSize: 18,
        lineHeight: 1.25,
        color: theme.palette.primary.contrastText,
        backgroundColor: "transparent",
        border: "none",
        position: "absolute",
        top: -75,
        right: 0,
        "&::before": {
          width: 18,
          height: 18,
          content: '""',
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url(" + close + ")",
          display: "block",
          margin: "0 auto 10px",
        },
      },
      "& .content": {
        [theme.breakpoints.up("md")]: {
          paddingRight: 64,
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& h3": {
          color: theme.palette.primary.contrastText,
          margin: 0,
          marginBottom: 32,
          letterSpacing: "-0.008em",
          fontSize: 32,
          fontFamily: theme.headerFont,
          textTransform: "uppercase",
          [theme.breakpoints.up("md")]: {
            fontSize: 52,
          },
        },
        "& p": {
          color: theme.palette.primary.contrastText,
          fontSize: 16,
          fontFamily: "Overpass",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          marginBottom: 32,
        },
        "& .links": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        },
        "& a": {
          padding: "5px 0",
          display: "table",
          borderBottom: "2px solid " + theme.palette.primary.contrastText,
          color: theme.palette.primary.contrastText,
          fontSize: 18,
          marginBottom: 12,
          textDecoration: "none",
          fontFamily: theme.bodyFont,
        },
        "& a.button": {
          marginRight: 15,
          fontSize: 18,
          fontFamily: theme.headerFont,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          textAlign: "center",
          padding: "10px 30px",
          border: "none",
          marginBottom: 8,
          boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
          borderRadius: 25,
          marginLeft: 0,
          transition: "background-color .3s ease-in-out",
          "&:hover, &:focus": {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      },
      "& .image-wrap": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
          flex: "0 0 50%",
          maxWidth: "50%",
        },
      },
    },
  },
  productOverviewTheme: {
    ...theme.productOverviewTheme,
  },
});
